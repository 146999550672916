/********************************************************************
 * Font Awesome
 * SVG JavaScript Core
 * https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core
 * https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons
 * https://fontawesome.com/icons?d=gallery
 */

import { library, dom } from '@fortawesome/fontawesome-svg-core'


// fas free-solid-svg-icons
import {
    faBars
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faBars
)


// far free-regular-svg-icons


// fab free-brands-svg-icons


// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()
