$(document).ready(function() {
    $("#navbarSupportedContent").mmenu({
        wrappers: ["bootstrap4"]

    }, {
        // configuration
        offCanvas: {
            pageSelector: "#page"
        }
    });
});