$(document).ready(function() {
    $('.lightbox').magnificPopup({
        type:'image',
        gallery: { enabled:true }
    });
});

document.addEventListener("DOMContentLoaded", function() {
    if($('#popover').length){
        $.magnificPopup.open({
            items: {
                src: '#popover'
            },
            type: 'inline'
        }, 0);
    }
});
